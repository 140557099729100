/* You can add global styles to this file, and also import other style files */
html, body{
  height: 100%;
  width: 100%;
  font-family: var(--font-family-primary);
  font-size: var(--font-size);
  color: var(--text-color);
}

a{
  color: var(--link-color);

  &:hover{
    color: var(--link-hover-color);
    text-decoration: var(--link-hover-text-decoration);
  }
}

.btn {
  border-radius: var(--button-radius);
}

.form-control {
  border-radius: var(--input-radius);
}

.padding-0{
  padding: 0;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus  {
  background-color: var(--primary-color) !important;
  border: 0;
}

.btn-secondary, .btn-secondary:hover, .btn-secondary:active, .btn-secondary:visited, .btn-secondary:focus {
  background-color: var(--secondary-color);
  border: 0;
}
